@font-face {
  font-family: 'Gotham';
  src: url('./../fonts/Gotham-Light.woff2') format('woff2'),
      url('./../fonts/Gotham-Light.woff') format('woff'),
      url('./../fonts/Gotham-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./../fonts/Gotham-Book.woff2') format('woff2'),
      url('./../fonts/Gotham-Book.woff') format('woff'),
      url('./../fonts/Gotham-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./../fonts/Gotham-Bold.woff2') format('woff2'),
      url('./../fonts/Gotham-Bold.woff') format('woff'),
      url('./../fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

body {
  background: #05040b;
  color: #9592AD;
  font-size: calc(1rem + 0vmin);
  overflow-x: hidden;
  font-family: 'Gotham';
  font-weight: 400;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#app {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

b {
  font-weight: 700 !important;
}

p {
  margin-bottom: 1.5rem !important;
}

fieldset {
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
}

fieldset > legend {
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}

img.responsive {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-dark {
  background-color: #212121;
}

.center {
  margin : 0 auto 0 auto;
}

.btn {
  transform: scale(1.5)
}

.hide {
  display: none !important;
}

.container-section {
  position: relative;
  width: calc(100%);
  height: auto;
  display:flex;
  justify-content: center;
}

.container-section.vh100 {
  height: 100vh;
}

.container-section.minvh100 {
  height: auto;
  min-height: 100vh;
}

.container-section.margin {
  width: calc(100% - 2rem);
  padding-right: 1rem;
  padding-left: 1rem;
}

.container-in.margin {
  padding-right: 1rem;
  padding-left: 1rem;
}

.absolute {
  position:absolute;
  top: 0;
  height: initial;
}

.absolute.bottom {
  top: auto;
  bottom: 0;
}

.absolute.inheritH {
  top: 0;
  bottom: 0;
}

.FAQ {
  border: 1px solid #fff;
  padding: 1rem;
  border-radius: 0.5rem;
}

.FAQ > .MuiAccordion-root {
  background-color: transparent;
}

.text-FAQ {
  color: #fff;
}

.FAQ > div:not(:last-child) {
  border-width: 0 0 0.5px 0;
  border-style: solid;
}

.partners-grid > div > div {
  text-align: center;
}

.partners-grid a img,
.partners-grid a:hover img {
  display: inline-block;
}

.divider-footer {
  width: 100%;
  height: 1px;
  background: rgb(34,36,39);
  background: linear-gradient(90deg, rgba(34,36,39,0) 0%, rgba(122,128,141,1) 53%, rgba(34,36,39,0) 100%);
}

.element-flash  {
  animation-duration: .8s;
  animation-name: flash;
  animation-iteration-count: infinite;
  transition: none;
}
@keyframes flash {
 0%   { opacity:1; }
 40%   {opacity:0; }
 100% { opacity:1; }
}

.MuiAccordion-root::before {
  opacity: 0 !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  background-color:#06050F;
  width:16px
}
::-webkit-scrollbar-track {
  background-color:#06050F;
}
::-webkit-scrollbar-thumb {
  background-color:#2e3444;
  border-radius:16px;
  border:5px solid #06050F;
}
::-webkit-scrollbar-button {display:none}
